import bfrt from "./static/img/bfrt.jpg"
import IMG1 from "./static/img/IMG1.jpg"
import IMG3 from "./static/img/IMG3.jpg"
import IMG4 from "./static/img/IMG4.jpg"
import IMG7 from "./static/img/IMG7.jpg"
import b1  from "./static/img/b1.jpg"
import b2 from "./static/img/b2.jpg"
import t7 from "./static/img/t7.jpg"
import n2 from "./static/img/n2.jpg"
import n3 from "./static/img/n3.jpg"
import n5 from "./static/img/n5.jpg"
import n7 from "./static/img/n7.jpg"
import n9 from "./static/img/n9.jpg"
import n10 from "./static/img/n10.jpg"
import n11 from "./static/img/n11.jpg"
import n12 from "./static/img/n12.jpg"
import n13 from "./static/img/n13.jpg"
import n14 from "./static/img/n14.jpg"
import n15 from "./static/img/n15.jpg"
import n16 from "./static/img/n16.jpg"
import n17 from "./static/img/n17.jpg"
import n18 from "./static/img/n18.jpg"
import n19 from "./static/img/n19.jpg"
import n20 from "./static/img/n20.jpg"
import p01 from "./static/preimg/01.jpg"
import p2 from "./static/preimg/2.jpg"
import p02 from "./static/preimg/02.jpg"
import p03 from "./static/preimg/03.jpg"
import p04 from "./static/preimg/04.jpg"
import p05 from "./static/preimg/05.jpg"
import p6 from "./static/preimg/6.jpg"
import p06 from "./static/preimg/06.jpg"
import p7 from "./static/preimg/7.jpg"
import p8 from "./static/preimg/8.jpg"
import p08 from "./static/preimg/08.jpg"
import p09 from "./static/preimg/09.jpg"
import p10 from "./static/preimg/10.jpg"
import p14 from "./static/preimg/14.jpg"
import p16 from "./static/preimg/16.jpg"

function Overview(){
    return (<>
<div class="intro-img">
    <img src={IMG4} alt="IMG4" />
    <div class="intro-img-content">
        <h3>Discover Ranthambore National Park: Where Nature's Beauty Meets Enchanting History</h3>
      </div>
</div>

<div class="para-content"  style={{ textAlign: 'center' }}>
  <h3>About Us</h3>
  <p>Cat tour travels ranthambore is here in ranthambore to provide complete tour & travel packages for our guests. It is about 5 km away from sawaimadhopur railway station and 3 km away from safari booking office. We resides in sawaimadhopur, so we know every nook of ranthambore. It provides Ranthambore National Park Safari, Village Tour, Ranthambore old city tour, lake visit, fort visit and vehicle on rent services for our guest.</p>
</div> 

<div class="para-content">
  <h3>Unveiling Ranthambore: A Natural Paradise</h3>
  <p>The park's history dates back to the 10th century when it was established as a hunting ground for the Maharajas of Jaipur. Today, it stands as one of the most celebrated wildlife sanctuaries in India. Ranthambore's varied topography encompasses dense forests, hilly terrain, and picturesque lakes, creating a perfect habitat for a wide array of flora and fauna. Ranthambore National Park is a masterpiece of nature's craftsmanship. This wilderness gem is a crucial part of the larger Ranthambore Tiger Reserve, an honored UNESCO World Heritage Site since 2013.</p>
</div>

<div class="para-content" sstyle={{ textAlign: 'center' }}>
  <h3><b> Exploring Ranthambore's Rich Heritage </b></h3>
</div>

<div class="para-content">
  <h3 class="hm">Ranthambore Tiger Reserve</h3>
  <p><img class="para-img1" src={p01} alt="IMG4" /><h3 class="hw">Ranthambore Tiger Reserve</h3> Ranthambore is best known for its large tiger population. Ranthambore wildlife sanctuary is known for its tigers and is one of the best places in India to see these animals in their natural jungle habitat. Tigers can be easily spotted even in the daytime.</p>
</div>

<div class="para-content">
  <h3 class="hm">Jungle Safari</h3>
  <p><img class="para-img2" src={p02} /><h3 class="hw">Jungle Safari</h3> There are two types of vehicles permitted on safari in Ranthambore National Park. the Jeep and the Canter. The Jeep (Gypsy) is the vehicle of choice for most visitors to Ranthambore. It is shared amongst 6 passengers, though we can reserve a private Jeep too.</p>
</div>

<div class="para-content">
  <h3 class="hm">Ranthambore Fort</h3>
  <p><img class="para-img1" src={p14} /><h3 class="hw">Ranthambore Fort</h3> Ranthambore Fort lies within the Ranthambore National Park, near the town of Sawai Madhopur, the park being the former hunting grounds of the Maharajahs of Jaipur until the time of India's Independence.</p>
</div>

<div class="para-content">
  <h3 class="hm">Jogi Mahal</h3>
  <p><img class="para-img2" src={p04} /><h3 class="hw">Jogi Mahal</h3> Jogi Mahal is a forest rest house. As normally Ranthambore is very dry throughout the year, Padam Talao, which is full with water year round is a favorite hunting area of the Tigers as the thirsty animals come to drink it.</p>
</div>

<div class="para-content">
  <h3 class="hm">Lake View</h3>
  <p><img class="para-img1" src={p10} /><h3 class="hw">Lake View</h3> There is a beaufitul lake view around the park. Wild animals like tiger, Spotted deer (Chital), Blue bull (Nilgai), Chinkara (Indian gazelle) and Wild boar can be easily seen around the lakes and ponds as they come to drink water..</p>
</div>

<div class="para-content">
  <h3 class="hm">Padam Talao</h3>
  <p><img class="para-img2" src={p06} /><h3 class="hw">Padam Talao</h3> Padam Talaos is the largest Lake present in the park. The famous and beautiful Jogi Mahal is located at the very edge of this lake. When in season, the water lilies that give the lake it's name, can be seen in plenty. In the early hours of the morning and just before sunset, animals can be seen in large numbers on the lake side.</p>
</div>

<div class="para-content">
  <h3 class="hm">Tigers in Ranthambore</h3>
  <p><img class="para-img1" src={p16} /> <h3 class="hw">Tigers in Ranthambore</h3>Ranthambore is one of the best national parks in the country for spotting a tiger. Ranthambore is best known for its large tiger population. According to the 2014 census of tigers, there were 62 tigers in Ranthambore National Park.[4] The number of tigers was 48 in 2013 and 25 in 2005.</p>
</div>

<div class="para-content">
  <h3 class="hm">Spotted Deer</h3>
  <p><img class="para-img2" src={p08} /><h3 class="hw">Spotted Deer</h3> Besides tigers, the other wild animals found in Ranthambore tiger reserve include Sambhar, Spotted deer (Chital), Blue bull (Nilgai), Chinkara (Indian gazelle) and Wild boar. The other large mammals that can be seen in Ranthambore tiger reserve are the Sloth bear, Indian fox, Jackal, the extremely occasional Wolf, very few Indian wild dogs (Dhole), Small Indian Civet, Palm civet, Common Indian and Ruddy mongoose and Striped Hyena.</p>
</div>

<div class="para-content">
  <h3 class="hm">Zones In Ranthambore</h3>
  <p><img class="para-img1" src={p09} /><h3 class="hw">Zones In Ranthambore</h3> Tourism at Ranthambore is conducted in 10 designated safari zones. Limited number of vehicles are allowed on each safari zone during each shift so that vehicles are evenly distributed across the park and there is no over crowding in areas with higher sighting probability.</p>
</div>

    </>)
}

export default Overview;
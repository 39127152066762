import IMG1 from "./static/img/IMG1.jpg"
import t24 from "./static/img/t24.jpg"
import machali from "./static/img/machali.jpg"
import sundari from "./static/img/sundari.jpg"
import n4 from './static/img/n4.heic'

const Tigers = () => {
    return (<>
      <div class="intro-img">
    <img src={IMG1} alt=""/>
    <div class="intro-img-content">
        <h3>Ranthambore's Diverse Tiger Species:</h3>
      </div>
</div>

<div class="para-content">
    <p>Ranthambore National Park, nestled in the heart of Rajasthan, India, stands as a testament to India's rich wildlife heritage. Among its many treasures, the park boasts a vibrant population of tigers, the apex predators of this pristine wilderness. These tigers represent diverse subspecies, each distinct in appearance and behavior:</p>
</div>

<div class="para-content">
    <h3 >Legendary Tigers and Their Tales:</h3>
    <p>Within the annals of Ranthambore's history, certain tigers have left an indelible mark, their stories resonating as testament to the park's enduring allure:</p>
</div>

<div class="para-content">
    <h3 class="hm">Machli, The Queen Mother:</h3>
    <p><img class="para-img2" src={machali}/> <h3 class="hw">Machli, The Queen Mother:</h3>Machli, revered as the "Queen Mother of Ranthambore," reigned over the park for years. Her regal bearing and remarkable hunting prowess earned her the moniker "Machli," meaning "fish" in Hindi, due to her extraordinary fishing skills. Her maternal instincts and longevity made her an iconic figure in the realm of wildlife conservation.</p>
</div>

<div class="para-content">
    <h3 class="hm">Ustad, The Dominant Male:</h3>
    <p><img class="para-img1" src={t24}/> <h3 class="hw">Ustad, The Dominant Male:</h3>Ustad, a formidable male tiger, stood as an embodiment of strength and dominance within the park. Recognizable by the distinctive notch in his ear, he stirred discussions about the complex dynamics of human-tiger conflicts and conservation endeavors.</p>
</div>

<div class="para-content">
    <h3 class="hm">Sundari, The Fearless Adventurer:</h3>
    <p><img class="para-img2" src={sundari}/><h3 class="hw">Sundari, The Fearless Adventurer:</h3> Sundari, a fearless tigress, left her mark through daring exploits and an uncanny ability to adapt to ever-changing environments. Her territory encompassed some of the most challenging terrains in the park, leading her on adventurous journeys to the farthest corners of Ranthambore.</p>
</div>


<div class="para-content">
    <h3 class="hm">Bengal Tiger (Panthera tigris tigris):</h3>
    <p><img class="para-img1" src={n4}/>
    <h3 class="hw">Bengal Tiger (Panthera tigris tigris):</h3>   
        Prevalent in Ranthambore, the Bengal tiger is a sight to behold with its iconic orange coat adorned by bold black stripes. However, what truly sets Ranthambore's Bengal tigers apart is the breathtaking spectrum of coat variations, from the classic orange and black to the coveted golden hues, making each individual a masterpiece of nature.</p>
</div>

<div class="para-content">
    <p>Ranthambore's tigers are not merely inhabitants of this natural wonder but revered symbols of the park's ecological richness. As we celebrate these magnificent creatures and their storied past, we are reminded of our solemn duty to protect and preserve their habitats for the benefit of future generations. With a tapestry of tiger species and the legacies of legendary individuals, Ranthambore continues to enchant nature enthusiasts and conservationists, serving as a beacon of hope for the conservation of India's iconic wildlife.</p>
</div>


    </>);
  };
  
export default Tigers;
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import NoPage from "./NoPage";
import Navbar from "./Navbar";
import Gallery from "./Gallery";
import Overview from "./Overview";
import Safari from "./Safari";
import HowToReach from "./HowToReach"
import Geography from "./Geography"
import Tigers from "./Tigers"
import Wildlife from "./Wildlife"
import Packages from "./Packages"
import Terms from "./Terms";
import Policy from "./Policy";

function Layout(){
    return (<>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="Overview" element={<Overview />} />
          <Route path="HowToReach" element={<HowToReach />} />
          <Route path="contact" element={<Contact />} />
          <Route path="Geography" element={<Geography />} />
          <Route path="Wildlife" element={<Wildlife />} />
          <Route path="Safari" element={<Safari />} />
          <Route path="Tigers" element={<Tigers />} />
          <Route path="Packages" element={<Packages />} />
          <Route path="Terms" element={<Terms />} />
          <Route path="Policy" element={<Terms />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>)
}
export default Layout;
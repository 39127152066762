import n4 from './static/img/n4.heic'
import p2 from './static/preimg/2.jpg'
import p03 from './static/preimg/03.jpg'
import p05 from './static/preimg/05.jpg'
import p6 from './static/preimg/6.jpg'
import zipimage from './static/img/zipimage.jpg'

const Packages = () => {
    return (<>

<div class="para-content"  style={{ textAlign: 'center' }}>
    <p>Cat tour travels ranthambore is leading tour & travel company located at main Ranthambore road.
        Cat tour travels ranthambore provides safaris and ranthambore services to tourists and many
        well known foreign travel agencies. Would be our pleasure to welcome you to Ranthambore.
        We wish you good luck to spot the tiger !</p>
</div>



<div class="para-content">
    <h3>Jungle Safari</h3>
    <p><img class="para-img1" src={n4}/>We provides Ranthambore National Park safari for our guest. Both Gypsy and canter can be book for jungle safari. </p>
    <a href="/contactus"><button>Book Now</button></a>
</div>

<div class="para-content">
    <h3>Hotel Booking</h3>
    <p><img class="para-img2" src={p2}/>We take care of our guest. On demand we arrange both A/c and Non A/c Hotels for our Guests. So, they can have comfortable stay.</p>
    <a href="/contactus"><button>Book Now</button></a>
</div>

<div class="para-content">
    <h3>Fort Visit</h3>
    <p><img class="para-img1" src={p03}/>Ranthambore Fort is famous for a great history behind victory of this fort. Fort visit makes tour more historical by its history.</p>
    <a href="/contactus"><button>Book Now</button></a>
</div>

<div class="para-content">
    <h3>Village Tour</h3>
    <p><img class="para-img2" src={p05}/>Rajasthani Villages are known for their culture and old lifestyle. A village tour make you feel about their culture and lifestyle.</p>
    <a href="/contactus"><button>Book Now</button></a>
</div>

<div class="para-content">
    <h3>City Tour</h3>
    <p><img class="para-img1" src={p6}/>City Tour cover all the old temples like Kala-Gora ji ka mandir, Jain Mandir and famous places of sawai madhopur city.</p>
    <a href="/contactus"><button>Book Now</button></a>
</div>

<div class="para-content">
    <h3>Vehicle On Rent</h3>
    <p><img class="para-img2" src={zipimage}/>Vehicle are available on rent for our guest. So, they can have a visit of famous places of Ranthambore independently.</p>
    <a href="/contactus"><button>Book Now</button></a>
</div>
    </>);
  };
  
export default Packages;

import IMG3 from "./static/img/IMG3.jpg"
import n4 from "./static/img/n4.heic"
import l1 from "./static/img/l1.jpg"
import br3 from "./static/img/br3.JPG"
import wb1 from "./static/img/wb1.jpeg"
import cr from "./static/img/cr.jpeg"

const Wildlife = () => {
    return (<>
    <div class="intro-img">
    <img src={IMG3} alt=""/>
    <div class="intro-img-content">
        <h3>Exploring the Rich Wildlife of Ranthambore National Park</h3>
    </div>
</div>

<div class="para-content">
    <p>Ranthambore National Park, nestled in the heart of Rajasthan, India, is an ecological gem that has captivated the hearts of wildlife enthusiasts and nature aficionados for decades. This sanctuary is not merely a testament to the marvels of the natural world; it is a haven for a vast and vibrant tapestry of species that have made it their home.</p>
</div>

<div class="para-content">
    <p><strong>The Diverse Ecosystem:</strong><br/>Ranthambore's landscape is a symphony of diverse ecosystems, encompassing thick forests, sprawling grasslands, winding rivers, and tranquil lakes. This varied topography provides an ideal habitat for an impressive range of wildlife species.</p>
</div>

<div class="para-content">
    <h3 class="hm">The Bengal Tiger</h3>
    <p><img class="para-img1" src={n4}/><h3 class="hw">The Bengal Tiger</h3>Undoubtedly, the crowning jewel of Ranthambore is the regal Bengal tiger (Panthera tigris tigris). These apex predators, with their distinctive orange coats adorned with black stripes, reign supreme in this realm of wilderness. Ranthambore is home to a significant population of these magnificent creatures, and the thrill of encountering one on a jungle safari is an experience never to be forgotten. Tigers here have their territories, and tracking their territorial movements is a fascinating aspect of Ranthambore's tiger conservation efforts.</p>
</div>

<div class="para-content">
    <h3  class="hm">Leopard</h3>
    <p><img class="para-img2" src={l1}/><h3 class="hw">Leopard</h3>Amidst the shadows and dappled sunlight of Ranthambore's forests, the elusive leopard (Panthera pardus) roams with stealth and grace. Though less frequently seen than tigers, leopards add an aura of mystery to the park. Their ability to adapt to a variety of habitats, from dense woods to rocky terrain, makes them masters of camouflage and survival.</p>
</div>

<div class="para-content">
    <h3  class="hm">Indian Wild Boar</h3>
    <p><img class="para-img1" src={wb1}/><h3 class="hw">Indian Wild Boar</h3>The Indian wild boar (Sus scrofa cristatus) is a common sight in Ranthambore's grasslands and woodlands. These robust animals are essential prey for the park's big cats. Their keen sense of smell and omnivorous diet make them vital contributors to the park's ecosystem.</p>
</div>

<div class="para-content">
    <h3  class="hm">Sloth Bear</h3>
    <p><img class="para-img2" src={br3}/><h3 class="hw">Sloth Bear</h3>Ranthambore is also home to the endearing sloth bear (Melursus ursinus). These shaggy-coated creatures, known for their unique shuffling gait, are expert climbers and spend their days foraging for insects, termites, and fruits. Spotting a mother bear with her cubs is a heartwarming experience.</p>
</div>

<div class="para-content">
    <h3  class="hm">Crocodiles and Turtles</h3>
    <p><img class="para-img1" src={cr}/><h3 class="hw">Crocodiles and Turtles</h3>The park's picturesque water bodies, such as Padam Talao and Raj Bagh Talao, host a population of marsh crocodiles (Crocodylus palustris) and various species of turtles. These prehistoric reptiles bask in the sun along the water's edge, their ancient lineage a testament to the park's timeless beauty.</p>
</div>

<div class="para-content">
    <h3>Additional Species</h3>
    <ul>
        <li><p><strong>Indian Gray Wolf:</strong> Ranthambore is also home to the Indian gray wolf (Canis lupus pallipes). These highly adaptive and social predators are a fascinating part of the park's carnivore community.</p></li>
        <li><p><strong>Sambar Deer:</strong> The park's woodlands are dotted with Sambar deer (Rusa unicolor), the largest species of deer in India. Their distinctive vocalizations often break the silence of the forest.</p></li>
        <li><p><strong>Chital (Spotted Deer):</strong> Among the most commonly sighted herbivores in Ranthambore is the chital (Axis axis), with its striking white spots on a reddish-brown coat.</p></li>
        <li><p><strong>Indian Gazelle (Chinkara):</strong> In the grasslands, you might spot the graceful Indian gazelle (Gazella bennettii), also known as chinkara, bounding through the open spaces.</p></li>
    </ul>
</div>

<div class="para-content">
    <h3>Birdlife</h3>
    <p>Ranthambore is not only a sanctuary for mammals but also a paradise for birdwatchers. The park boasts a staggering array of avian species, with over 270 recorded. Among them are:</p>
    <ul>
        <li><p><strong>Indian Peafowl (Pavo cristatus):</strong> Males of this species display their stunning iridescent plumage in courtship rituals.</p></li>
        <li><p><strong>Crested Serpent Eagle (Spilornis cheela):</strong> These majestic eagles soar overhead, scanning the landscape for prey.</p></li>
        <li><p><strong>Indian Vulture (Gyps indicus):</strong> Once critically endangered, these scavengers play a vital role in the park's ecosystem.</p></li>
        <li><p><strong>Malabar Pied Hornbill (Anthracoceros coronatus):</strong> With their distinctive casques and vibrant plumage, these hornbills are a delight to observe.</p></li>
        <li><p><strong>Painted Stork (Mycteria leucocephala):</strong> These elegant waders can often be seen wading in the park's water bodies, their bills open to catch fish.</p></li>
        <li><p><strong>Rufous Treepie (Dendrocitta vagabunda):</strong> These colorful and noisy birds are a common sight, often seen scavenging near park facilities.</p></li>
    </ul>
</div>

<div class="para-content">
    <p>Ranthambore National Park is not just a sanctuary; it is a living testament to the wonders of nature. Its diverse range of wildlife, from the regal Bengal tiger to the vibrant birdlife and the myriad of herbivores and reptiles, offers a glimpse into the intricate web of life that thrives within its boundaries. By protecting and preserving this unique ecosystem, we ensure that future generations can continue to marvel at the beauty of Ranthambore and its captivating inhabitants.</p>
</div>

    </>);
  };
  
export default Wildlife;
import React, { useState } from 'react';

const Safari = () => {
  return(<>

<div class="para-content" style={{ textAlign: 'center' }}>
    <p>Cat tour travels Ranthambore is leading tour & travel company located at main Ranthambore road.
        Cat tour travels Ranthambore provides safaris and Ranthambore services to tourists and many
        well known foreign travel agencies. Would be our pleasure to welcome you to Ranthambore.
        We wish you good luck to spot the tiger !</p>
</div>

<div class="container mt-5">
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          2N/3D accommodation in  ( 3 star ) & Ranthambore Safari in Canter ( sharing basis )
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>02 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>03 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        2N/3D Accommodation in 4 star property & Ranthambore Safari in Canter ( Sharing Basis )
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>02 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>03 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>

   
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        3N/4D Accommodation in 3 star property & Ranthambore Safari in Canter ( Sharing Basis )
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>04 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>05 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>

   
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFour">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        3N/4D Accommodation & Ranthambore Safari in Canter ( Sharing Basis )
        </button>
      </h2>
      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>04 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>05 Canter Safaris Ranthambore tiger reserve ( sharing basis ).  </li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>

 
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFive">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        2N/3D Accommodation in 3 star property & Ranthambore Safari by 4*4 Exclusive gypsy
        </button>
      </h2>
      <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>02 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                    <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>03 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>


    <div class="accordion-item">
      <h2 class="accordion-header" id="headingSix">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        2N/3D Accommodation in 4 star & Ranthambore Safari by 4*4 Exclusive gypsy
        </button>
      </h2>
      <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>02 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>03 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>

 
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingSeven">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
        3N/4D Accommodation in 3 star property & Ranthambore Safari by 4*4 Exclusive gypsy
        </button>
      </h2>
      <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>04 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>05 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>


    <div class="accordion-item">
      <h2 class="accordion-header" id="headingEight">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
        3N/4D Accommodation in 4 star property & Ranthambore Safari by 4*4 Exclusive gypsy
        </button>
      </h2>
      <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>04 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        <div class="acc-div1">
            <ul>
                <li>Pick up and drop from sawai madhopur railway station</li>
                <li>All meals ( Breakfast, Lunch & Dinner ).</li>
                <li>05 Normal Safari  Ranthambore tiger reserve ( sharing basis ).</li>
                <li>Ranthambore fort & Ganesh Temple visit..</li>
                <li>Fixed guide for all safari  </li>
                <li>Inclusive of all taxes</li>
                ( Minimum 2 people )
            </ul>
                <p>*For price click on enquiry</p><br/>
                <div class="enqry"><a href="/contactus"><button style={{ width: '100%' }}>Enquiry</button></a></div>
        </div>
        </div>
      </div>
    </div>

  </div>
</div>

  </>)
};

export default Safari;

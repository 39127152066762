// React imports
import IMG1 from "./static/img/IMG1.jpg";
import n2 from "./static/img/n2.jpg";
import IMG2 from "./static/img/IMG2.jpg";
import IMG3 from "./static/img/IMG3.jpg";
import IMG4 from "./static/img/IMG4.jpg";
import IMG5 from "./static/img/IMG5.jpg";
import IMG6 from "./static/img/IMG6.jpg";
import IMG7 from "./static/img/IMG7.jpg";
import t11 from "./static/img/t11.jpg";
import n9 from "./static/img/n9.jpg";
import n17 from "./static/img/n17.jpg";
import n15 from "./static/img/n15.jpg";
import n1 from "./static/img/n1.heic";
import n3 from "./static/img/n3.jpg";
import t4 from "./static/img/t4.jpg";
import t5 from "./static/img/t5.jpg";
import t6 from "./static/img/t6.jpg";
import t10 from "./static/img/t10.jpg";
import n14 from "./static/img/n14.jpg";
import n16 from "./static/img/n16.jpg";
import n8 from "./static/img/n8.webp";
import b2 from "./static/img/b2.jpg";
import preimg16 from "./static/preimg/16.jpg";
import preimg09 from "./static/preimg/09.jpg";
import b1 from "./static/img/b1.jpg";
import n10 from "./static/img/n10.jpg";
import n11 from "./static/img/n11.jpg";
import n12 from "./static/img/n12.jpg";
import n19 from "./static/img/n19.jpg";
import n6 from "./static/img/n6.webp";

import t7 from "./static/img/t7.jpg";
import t8 from "./static/img/t8.jpg";
import t9 from "./static/img/t9.jpg";
import b2_2 from "./static/img/b2.jpg"; // Note: Renamed to b2_2 to avoid conflict
import preimg16_2 from "./static/preimg/16.jpg"; // Note: Renamed to preimg16_2
import preimg09_2 from "./static/preimg/09.jpg"; // Note: Renamed to preimg09_2
import b1_2 from "./static/img/b1.jpg"; // Note: Renamed to b1_2
import n10_2 from "./static/img/n10.jpg"; // Note: Renamed to n10_2
import n11_2 from "./static/img/n11.jpg"; // Note: Renamed to n11_2
import n12_2 from "./static/img/n12.jpg"; // Note: Renamed to n12_2
import n19_2 from "./static/img/n19.jpg"; // Note: Renamed to n19_2
import n6_2 from "./static/img/n6.webp"; // Note: Renamed to n6_2


// React component
function ImageGallery() {
  return (
    <div className="gridgallary">
      <div className="row">
        <div className="column">
          <img src={IMG1} alt="Image 1" />
          <img src={n2} alt="Image 2" />
          <img src={IMG2} alt="Image 3" />
          <img src={IMG3} alt="Image 4" />
          <img src={IMG4} alt="Image 5" />
          <img src={IMG5} alt="Image 6" />
          <img src={IMG6} alt="Image 7" />
          <img src={IMG7} alt="Image 8" />
          <img src={t11} alt="Image 9" />
          <img src={n9} alt="Image 10" />
          <img src={n17} alt="Image 11" />
          <img src={n15} alt="Image 12" />
        </div>
        <div className="column">
          <img src={n1} alt="Image 13" />
          <img src={n3} alt="Image 14" />
          <img src={t4} alt="Image 15" />
          <img src={t5} alt="Image 16" />
          <img src={t6} alt="Image 17" />
          <img src={t10} alt="Image 18" />
          <img src={n14} alt="Image 19" />
          <img src={n16} alt="Image 20" />
          <img src={n8} alt="Image 21" />
          {/* Add more images here */}
        </div>
        <div className="column">
          <img src={b2} alt="Image 22" />
          <img src={preimg16} alt="Image 23" />
          <img src={preimg09} alt="Image 24" />
          <img src={b1} alt="Image 25" />
          <img src={n10} alt="Image 26" />
          <img src={n11} alt="Image 27" />
          <img src={n12} alt="Image 28" />
          <img src={n19} alt="Image 29" />
          <img src={n6} alt="Image 30" />
          {/* Add more images here */}
        </div>
        <div className="column">
          <img src={b2_2} alt="Image 31" />
          <img src={preimg16_2} alt="Image 32" />
          <img src={preimg09_2} alt="Image 33" />
          <img src={b1_2} alt="Image 34" />
          <img src={n10_2} alt="Image 35" />
          <img src={n11_2} alt="Image 36" />
          <img src={n12_2} alt="Image 37" />
          <img src={n19_2} alt="Image 38" />
          <img src={n6_2} alt="Image 39" />
          {/* Add more images here */}
        </div>
        <div className="column">
          {/* Continue adding images for this column */}
        </div>
        {/* Repeat for other columns */}
      </div>
    </div>
  );
}

export default ImageGallery;

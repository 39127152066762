const Footer = () => {
    return (
        <>
            <div className="scrb">
                <div className="scrb-h"><p>Query For Booking:</p></div>
                <form action="" method="post" id="myForm1">
                    {/* Add the appropriate way to include CSRF token in your form */}
                    {/* For example: <input type="hidden" name="csrfmiddlewaretoken" value="{% csrf_token %}" /> */}
                    <input type="text" name="fname" placeholder="Name" />
                    <input type="text" name="mnum" placeholder="Mobile No." />
                    <input type="email" name="emailadd" id="" placeholder="Email" />
                    <input type="submit" value="Submit" />
                </form>
            </div>

            <div className="icon-bar">
                <a href="https://wa.me/9256718938" className="wtsp"><i className="bi bi-whatsapp"></i></a>
            </div>

            <div className="footer">
            <div class="footer-div">
            <h3>Ranthambore</h3>
            <a href="/overview">Overview</a>
            <a href="/howtoreach">How To Reach</a>
            <a href="/geography">Geography</a>
            <a href="/wildlife">Wild Animals</a>
            <a href="/wildlife">Additional Species</a>
            <a href="/wildlife">BirdLife</a>
            <a href="/tiger">Tigers </a>
            <a href="/contactus">Contact Us</a>
        </div>
        <div class="footer-div">
            <h3>Links</h3>
            <a href="/">Home</a>
            <a href="/overview">About Us</a>
            <a href="/packages">Our Services</a>
            <a href="/safari">Safari Packages</a>
            <a href="/terms">Terms & Conditions </a>
            <a href="/policy">Privacy Policy </a>
            <a href="/contactus">Contact Us</a>
        </div>
        <div class="footer-div">
            <h3>Services</h3>
            <a href="/safari">2N/3D Tour</a>
            <a href="/safari">3N/4D Tour</a>
            <a href="/safari">Safari In Canter</a>
            <a href="/safari">Gypsy and Safari</a>
            <a href="/packages">Fort Visit</a>
            <a href="/packages">Jungle safari</a>
            <a href="/packages">Hotel Booking</a>
            <a href="/packages">Vehicle On Rent</a>
            <a href="/packages">Village Tour</a>
            <a href="/packages">City Tour</a>
            <a href="/contactus">Enquiry Now</a>
        </div>
        <div class="footer-div i-fdiv">
            <h3>Connect With Us</h3>
            <a href="" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://wa.me/9256718938" target="_blank"><i class="bi bi-whatsapp"></i></a>
            <a href="https://www.instagram.com/cat_tour_travels/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank"><i class="bi bi-instagram"></i></a>
            <br/><br/>
            <h3>Contact Us</h3>
            <div>
    <ul>
        <li><i className="bi bi-telephone" style={{ fontSize: 'small' }}></i></li>
        <li><a href="tel:9256718938">+91 9256718938</a></li>
    </ul>
</div>
<div>
    <ul>
        <li><i className="bi bi-envelope" style={{ fontSize: 'small' }}></i></li>
        <li><a href="mailto:tanveer@cattourtravelsranthambore.com">Send Mail</a></li>
    </ul>
</div>

        </div>
            </div>

            <div className="cpryt">
                <div>Copyright © Cat Tours & Travels | Explore Ranthambore With Us | All Rights Reserved.</div>
            </div>

            <div className="sh">
                <a href="https://www.synthesishive.com">SynthesisHive.com</a>
            </div>
        </>
    );
};

export default Footer;

const Terms = () => {
    return (<>
        <div class="para-content">
    <br/>
    <h1 style={{ textAlign: 'center' }}>Terms and Conditions</h1>
    <br/>
    <p>Welcome to www.cattourtravelsranthambore.com (website). The domain name www.cattourtravelsranthambore.com is owned by Ranthambore based travel company “Cat Tour Travels Ranthambore. Here we use the term "You" that refers to the direct user or viewer of the website, If you will use the service given by this website it means you will agreed with all our terms and conditions.</p>
</div>

<div class="para-content">
    <p><strong>Jeep Safari &amp; Canter Safari Payments are non-refundable</strong><br/>Jeep Safari / Canter Safari charges are non-refundable. Current booking safari charges will be refunded in case booking is not confirmed.</p>
</div>

<div class="para-content">
    <p><strong>Our Liabilities and Limitations : </strong> Please note that after you finalize the tour/service cost and in case if there are any hikes in permit fees of safaris/ taxes, fuel cost or guide charges the same would be charged as extra.</p>
</div>

<div class="para-content">
    <p><strong>Current/Tatkal Booking of Ranthambore National Park </strong> </p>
    <p>Important Notes for Current Bookings:</p>
    <p>1. Submit booking request at least one day before.</p>
    <p>2. 100% confirmation of current booking is not assured.</p>
    <p>3. Your safari zone may be any one of 1 to 10 zones of Ranthambore Tiger Reserve.</p>
    <p>4.Any specific zone booking is not guaranteed; however, we try our best to book according to your choice.</p>
</div>

<div class="para-content">
    <p><strong>Things to Keep in Mind for Ranthambore Safari Booking:</strong></p>
    <ul>
        <li>You have to pay full fees in advance to make safari bookings.</li>
        <li>ID proof is must for each and every visitor.</li>
        <li>No refund or cancellation is permitted on confirmed bookings.</li>
        <li>Booking is non-transferable and cannot be exchanged with anyone.</li>
        <li>You must carry the same ID card that you have submitted during the online booking.</li>
        <li>You can book your safari maximum of 90 days in advance.</li>
        <li> Passport details are mandatory for foreigner tourists for making reservations.</li>
        <li>Safari permit is issued on a first come, first serve basis (subject to availability).</li>
        <li> Park may be closed without any prior notice to visitors.</li>
        <li>In case of any revision of fees after the booking, visitors will be liable to pay the difference at the time of entry into the park.</li>
        <li>The visitors are required to report at the boarding place 15 min prior to the scheduled departure of the safari.</li>
        <li>If you book safari for next day, your booking will be considered as ‘current booking’ and all terms &amp; conditions will be applied as of current bookings.</li>
    </ul>
</div>

<div class="para-content">
    <p><strong>*Please Note*</strong></p>
    <p>All reservations for use of any facility including entry and stay in Ranthambore Tiger Reserve are provisional in nature and are subject to modification/cancellation by the Field Director, Ranthambore Tiger Reserve without assigning any reasons. Making a reservation will imply your agreement to any and all of the terms and conditions laid down here. There shall be no cancellation/refund for any safari under any circumstances and no request shall be entertained for refund/rescheduling of safari.</p>
</div>

    </>);
  };
  
  export default Terms;
import React, { useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './styles.css';
import './index.css';
import './Home.css';

import bfrt from "./static/img/bfrt.jpg"
import IMG1 from "./static/img/IMG1.jpg"
import IMG3 from "./static/img/IMG3.jpg"
import IMG4 from "./static/img/IMG4.jpg"
import IMG7 from "./static/img/IMG7.jpg"
import b1  from "./static/img/b1.jpg"
import b2 from "./static/img/b2.jpg"
import t7 from "./static/img/t7.jpg"
import n2 from "./static/img/n2.jpg"
import n3 from "./static/img/n3.jpg"
import n5 from "./static/img/n5.jpg"
import n7 from "./static/img/n7.jpg"
import n9 from "./static/img/n9.jpg"
import n10 from "./static/img/n10.jpg"
import n11 from "./static/img/n11.jpg"
import n12 from "./static/img/n12.jpg"
import n13 from "./static/img/n13.jpg"
import n14 from "./static/img/n14.jpg"
import n15 from "./static/img/n15.jpg"
import n16 from "./static/img/n16.jpg"
import n17 from "./static/img/n17.jpg"
import n18 from "./static/img/n18.jpg"
import n19 from "./static/img/n19.jpg"
import n20 from "./static/img/n20.jpg"

const Home = () => {





  useEffect(() => {
    let isDragging = false;
    let isAutoPlay = true; // Added this line
    let startX, startScrollLeft, timeoutId;
    const imgSlider = document.querySelector(".img-slider");
    const imgUl = document.querySelector(".img-ul");
    const firstImgLiWidth = imgUl.querySelector(".img-li").offsetWidth;
    const arrowBtns = document.querySelectorAll(".img-slider span");
    const imgUlChildren = [...imgUl.children];
  
    let imgLiPerView = Math.round(imgUl.offsetWidth / firstImgLiWidth);
  
    imgUlChildren.slice(-imgLiPerView).reverse().forEach(imgLi => {
      imgUl.insertAdjacentHTML("afterbegin", imgLi.outerHTML);
    });
  
    imgUlChildren.slice(0, imgLiPerView).forEach(imgLi => {
      imgUl.insertAdjacentHTML("beforeend", imgLi.outerHTML);
    });
  
    imgUl.classList.add("no-transition");
    imgUl.scrollLeft = imgUl.offsetWidth;
    imgUl.classList.remove("no-transition");
  
    arrowBtns.forEach(btn => {
      btn.addEventListener("click", () => {
        imgUl.scrollLeft += btn.id === "left" ? -firstImgLiWidth : firstImgLiWidth;
      });
    });
  
    const dragStart = (e) => {
      isDragging = true;
      imgUl.classList.add("dragging");
      startX = e.pageX;
      startScrollLeft = imgUl.scrollLeft;
    };
  
    const dragging = (e) => {
      if (!isDragging) return;
      imgUl.scrollLeft = startScrollLeft - (e.pageX - startX);
    };
  
    const dragStop = () => {
      isDragging = false;
      imgUl.classList.remove("dragging");
    };
  
    const infiniteScroll = () => {
      if (imgUl.scrollLeft === 0) {
        imgUl.classList.add("no-transition");
        imgUl.scrollLeft = imgUl.scrollWidth - 2 * imgUl.offsetWidth;
        imgUl.classList.remove("no-transition");
      } else if (Math.ceil(imgUl.scrollLeft) === imgUl.scrollWidth - imgUl.offsetWidth) {
        imgUl.classList.add("no-transition");
        imgUl.scrollLeft = imgUl.offsetWidth;
        imgUl.classList.remove("no-transition");
      }
      clearTimeout(timeoutId);
      if (!imgSlider.matches(":hover")) autoPlay();
    };
  
    const autoPlay = () => {
      if (window.innerWidth < 100 || !isAutoPlay) return;
      timeoutId = setTimeout(() => (imgUl.scrollLeft += firstImgLiWidth), 2000);
    };
    autoPlay();
  
    imgUl.addEventListener("mousedown", dragStart);
    imgUl.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    imgUl.addEventListener("scroll", infiniteScroll);
    imgSlider.addEventListener("mouseenter", () => clearTimeout(timeoutId));
    imgSlider.addEventListener("mouseleave", autoPlay);
  }, []); // Empty dependency array ensures that this effect runs once when the component mounts
  
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper homemain"
      >
        <SwiperSlide><img src={IMG4} alt="IMG4" /></SwiperSlide>
        <SwiperSlide><img src={IMG3} alt="IMG3" /></SwiperSlide>
        <SwiperSlide><img src={IMG7} alt="IMG7" /></SwiperSlide>
        <SwiperSlide><img src={IMG1} alt="IMG1" /></SwiperSlide>
      </Swiper>
      <div class="home-page">

      <div class="glry">
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n14} alt="n14" /></a>
        <div class="desc"><a href="/wildlife"><button class="button-5">Ranthambore</button></a></div>
      </div>
    </div>
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n10} alt="n10" /></a>
        <div class="desc"><a href="#srsk"><button class="button-5">Sariska</button></a></div>
      </div>
    </div>
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n17} alt="n17" /></a>
        <div class="desc"><a href="#rmgr"><button class="button-5">Ramgarh</button></a></div>
      </div>
    </div>
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={bfrt} alt="bfrt" /></a>
        <div class="desc"><a href="#bnd"><button class="button-5">Bundi Fort</button></a></div>
      </div>
    </div>
  </div>

  <br/><br/>
  <h3>Journey into the wild</h3>

  <div className="para-content" style={{ textAlign: 'center' }}>
  <p>
    Cat tour travels Ranthambore is here in Ranthambore to provide complete tour & travel packages for our guests. It is about 5 km away from Sawai Madhopur railway station and 3 km away from the safari booking office. We reside in Sawai Madhopur, so we know every nook of Ranthambore. It provides Ranthambore National Park Safari, Village Tour, Ranthambore old city tour, lake visit, fort visit, and vehicle on rent services for our guests.
  </p>
</div>

<p><h3>Ranthambore's Diverse Tiger Species:</h3></p>

  <div class="glry">
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n16} alt="n14" /></a>
        <div class="desc"><a href="/tiger"><button>Explore ...</button></a></div>
      </div>
    </div>
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n7} alt="n14" /></a>
        <div class="desc"><a href="/tiger"><button>Explore ...</button></a></div>
      </div>
    </div>
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n12} alt="n14" /></a>
        <div class="desc"><a href="/tiger"><button>Explore ...</button></a></div>
      </div>
    </div>
    <div class="responsive">
      <div class="gallery">
        <a target="_blank"><img src={n13} alt="n14" /></a>
        <div class="desc"><a href="/tiger"><button>Explore ...</button></a></div>
      </div>
    </div>
  </div>

  <div class="para-content" style={{ textAlign: 'center' }}>
    <p>The true stars of Ranthambore are its tigers. The park is home to a significant number of these magnificent creatures, and spotting one in the wild is a thrilling experience. While tigers steal the spotlight, Ranthambore also hosts a diverse range of wildlife, including leopards, sloth bears, crocodiles, and an abundance of bird species. The park's thriving ecosystem showcases the interconnectedness of life in its most raw and untamed form.</p>
  </div>

  <div class="img-slider">
  <span id="left"  class="material-symbols-outlined">
    chevron_left
    </span>
  <ul class="img-ul">
    <li class="img-li"><div class="img"><img src={b1} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={t7} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={b2} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n2} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n3} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n5} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n7} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n9} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n10} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n11} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n12} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n13} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n14} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n15} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n16} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n17} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n18} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n19} alt="n14" /></div></li>
    <li class="img-li"><div class="img"><img src={n20} alt="n14" /></div></li>
  </ul>
  <span id="right"  class="material-symbols-outlined">
    chevron_right
    </span>
</div>
      
      <div class="para-content" style={{ textAlign: 'center' }}>
  <h3>Cat Tour & Travels | Explore Ranthambore With Us</h3>
  <p>Cat tour travels Ranthambore is here to create ranthambore tour for our guests
    We deal in following services.</p>
</div>

<div class="osdiv">
  <div class="os-responsive">
    <div class="os-gallery">
      <a target="_blank"  href="/contactus"><span class="material-symbols-outlined">castle</span></a>
      <div class="desc">Fort Visit</div>
    </div>
  </div>
  <div class="os-responsive">
    <div class="os-gallery">
      <a target="_blank"  href="/contactus"><span class="material-symbols-outlined">forest</span></a>
      <div class="desc">Jungle safari</div>
    </div>
  </div>
  <div class="os-responsive">
    <div class="os-gallery">
      <a target="_blank" href="/contactus"><span class="material-symbols-outlined">hotel</span></a>
      <div class="desc">Hotel Booking</div>
    </div>
  </div>
  <div class="os-responsive">
    <div class="os-gallery">
      <a target="_blank"  href="/contactus"><span class="material-symbols-outlined">directions_car</span></a>
      <div class="desc">Vehicle On Rent</div>
    </div>
  </div>
  <div class="os-responsive">
    <div class="os-gallery">
      <a target="_blank"  href="/contactus"><span class="material-symbols-outlined">map</span></a>
      <div class="desc">Village Tour</div>
    </div>
  </div>
  <div class="os-responsive">
    <div class="os-gallery">
      <a target="_blank" href="/contactus"><span class="material-symbols-outlined">villa</span></a>
      <div class="desc">City Tour </div>
    </div>
  </div>
</div>


<h3 id="srsk">Exploring the Serene Beauty of Sariska</h3>
<div class="glry">
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n19} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n11} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n2} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n10} alt="n10" /></a>
    </div>
  </div>
</div>
<div class="para-content" style={{ textAlign: 'center' }}>
  <p>Sariska, a captivating wildlife sanctuary, is renowned for its diverse flora and fauna, offering a unique opportunity for nature enthusiasts to immerse themselves in the natural beauty of Rajasthan. Located in the Alwar district, this sanctuary spans over 800 square kilometers and is home to a wide range of wildlife, including tigers, leopards, hyenas, and various bird species. Visitors can embark on thrilling safaris through its picturesque landscapes, spotting majestic creatures in their natural habitat. Additionally, Sariska's historical and architectural treasures, such as the Sariska Palace and the ancient Kankwari Fort, add a rich layer of cultural significance to this natural haven, making it a must-visit destination for anyone exploring the wonders of Rajasthan.</p>
  <a href="/contactus"><button>Book Now</button></a>
</div>

<h3 id="rmgr">Exploring the Scenic Beauty of Ramgarh, Rajasthan</h3>
<div class="glry">
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n20} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n12} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n10} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n3} alt="n10" /></a>
    </div>
  </div>
</div>
<div class="para-content" style={{ textAlign: 'center' }}>
  <p>Ramgarh is a picturesque town nestled amidst the hills, boasting serene landscapes that provide a perfect retreat from the hustle and bustle of city life. With its tranquil atmosphere and stunning natural beauty, this charming town is an ideal destination for those seeking a peaceful getaway. Additionally, Ramgarh offers a variety of outdoor activities and cultural experiences, making it a well-rounded destination for travelers of all interests. You can explore scenic hiking trails, enjoy local cuisine at quaint eateries, and immerse yourself in the rich cultural heritage of the region by visiting historical sites and engaging with the welcoming local community.</p>
  <a href="/contactus"><button>Book Now</button></a>
</div>

<h3 id="bnd">Bundi Fort: A Historical Marvel in Rajasthan</h3>
<div class="glry">
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n9} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n7} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n15} alt="n10" /></a>
    </div>
  </div>
  <div class="responsive">
    <div class="gallery">
      <a target="_blank"><img src={n17} alt="n10" /></a>
    </div>
  </div>
</div>
<div class="para-content" style={{ textAlign: 'center' }}>
  <p>Bundi Fort, also known as Taragarh Fort, is a remarkable historical fortress nestled atop a hill in Rajasthan's Bundi city. This ancient stronghold showcases intricate Rajput architecture with its grand walls, ornate gateways, and the charming Taragarh Palace within its premises. Visitors can explore the palace's beautiful frescoes, enjoy panoramic views, and discover the fort's historical significance. The fort also houses impressive structures like Bhim Burj and Garh Palace, as well as picturesque stepwells. During festivals like Teej and Bundi Utsav, the fort comes alive with cultural festivities, making it a must-visit destination for history and architecture enthusiasts.</p>
  <a href="/contactus"><button>Book Now</button></a>
</div>

</div>

<div class="para-content">
  <h3>Why choose us</h3>
  <ul>
    <li><strong>Jungle Safari Booking:</strong>We are here to book Jungle safari for our guest. We are well known for jungle safari booking service. We are from ranthambore city. We know every edge of ranthambore. We are always few min. drive away from you in Ranthambore.</li>
    <li><strong>Best deal in Hotels:</strong>We are also best in hotel booking service. We always knows the need of our Guests. We provides best deal in hotel for our guests.</li>
    <li><strong>Attractive Packages</strong>We have great package deals. All the tour packages covers every tourist place which you need to visit. Ranthambore tour package covers every edge of ranthambore, famous places, old city, villages, lakes and fort.</li>
    <li><strong>Reasonable Price</strong>Our pricing are always reasonable. We charge according to the service quality which we serve. We are here in competitive envirement but we maintaing our pricing for our guests as we believe in service not in princing.</li>
  </ul>
</div>

<>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className='testimonials'><div><p>Wonderful Safari experience through Cat tours. We stayed for 5 nights in Ranthambore  and did 8 safaris. They arranged for all the safaris and we got a chance to visit all the happening zones and had a great sighting experience. The gypsy was on time for all safaris and the staff drivers and guide  were very knowledgeable for the best sighting. Highly recommended and please plan your Safari booking at least 1 to 2 months in advance.</p><p class="ow">Kumararaj Balachandran</p></div></SwiperSlide>
        <SwiperSlide  className='testimonials'><div><p>Had a great experience with cat tour and travels, i was searching to book a gypsy safari tour in zone 1-6 for afternoon and every other agent in city was asking too much money then i met Mr. Pathan from Cat Tour Travels and he got me a confirmed booking for the gypsy safari at a very nominal charge, almost at half price from other people. Also Mr. Pathan was very polite and helpful, and he was in touch with me all the time from getting the ride booked, having the gypsy at my hotel to reaching back at hotel after the safari.</p><p class="ow">Anand Singh</p></div></SwiperSlide>
        <SwiperSlide className='testimonials'><div><p>Tanveer has always ensured we have an excellent experience during our safaris. And this time as per his suggestion we visited zones 1& 4 and were able to spot 6 tigers at different occasions. It was a lifetime experience and we're truly grateful for the services offered.</p><p  class="ow">Sumegha Kaushal</p></div></SwiperSlide>
        <SwiperSlide className='testimonials'><div><p>I had a pleasant experience while coordinating with Tanveer ji about my Ranthambore trip. He has been really patient with me while answering my all queries while planning the whole trip. They helped us to get a VIP zone to have a ecstatic experience of jungle safari and yes we were lucky enough to site a tiger.Needless to say they are amazing and just simply go for them for planning a trip to ranthambore. Thanks team for making our trip a memorable one..:)</p><p class="ow">Shweta</p></div></SwiperSlide>
        <SwiperSlide className='testimonials'><div><p>Got zone one on Christmas Day and got the best gift possible. Tigress no. 105 leading us from the front patrolling her territory. Though zone 3 is best known for sighting tigers their timely uptodate knowledge of tiger sightings helped me to witness my first live tiger sighting that too a cat walk for 10mins. Thank you</p><p class="ow">Aakash M</p></div></SwiperSlide>
        <SwiperSlide className='testimonials'><div><p>It’s always been an amazing experience whenever we booked our safari with tanvir ji.. a very humble and patient coordinator. Most of my bookings we done on 11th hour but he never say No.. Best experience so far.</p><p class="ow">Richa Tiwari</p></div></SwiperSlide>
        <SwiperSlide className='testimonials'><div><p>One of the best services I have received,  Mr Tanveer was very helpful from arranging us the cabs to providing us safari passes on last minute,
        We spotted tiger all thanks to him for providing us the passes for the right zone.
        Thank you Tanveer and Cat Tour and Travels for making our Ranthambore trip memorable.</p><p class="ow">Aditya Ojha</p></div></SwiperSlide>
      </Swiper>
    </>
  </>);
};

export default Home;

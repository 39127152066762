import logo from "./static/img/logo.png"

import { Outlet, Link } from "react-router-dom";

const Navbar = () => {
  return (<>
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="/"><img src={logo} alt="IMG4" /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Ranthambore</a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to="/Overview">Overview</Link></li>
            <li><Link className="dropdown-item" to="/HowToReach">How to reach</Link></li>
            <li><Link className="dropdown-item" to="/Geography">Geography</Link></li>
          </ul>
        </li>
        <li className="nav-item"><Link className="nav-link" to="/Wildlife">Wildlife</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/Safari">Safari</Link></li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">PACKAGES</a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to="/Safari">Jungle Safari</Link></li>
            <li><Link className="dropdown-item" to="/Packages">Hotel Booking</Link></li>
            <li><Link className="dropdown-item" to="/Packages">Fort Visit</Link></li>
            <li><Link className="dropdown-item" to="/Packages">Village Tour</Link></li>
            <li><Link className="dropdown-item" to="/Packages">City Tour</Link></li>
            <li><Link className="dropdown-item" to="/Packages">Vehicle On Rent</Link></li>
          </ul>
        </li>
        <li className="nav-item"><Link className="nav-link" to="/Tigers"> TIGERS </Link></li>
        <li className="nav-item"><Link className="nav-link" to="/Gallery">Gallery</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/contact">contact</Link></li>
      </ul>
    </div>
  </div>
</nav>

      <Outlet />
    </>)
};

export default Navbar;